


//@News


main.news {
	h1 {
		@include mb(0px); }
	figure {
		@include bradius($bradius);
		background-size: cover!important; }
	.follow {
		.social {
			@include tCenter;
			margin: 20px 0;
			a {
				@include ib;
				@include tCenter;
				@include size(40px);
				@include radius(100%);
				margin: 0 3px;
				@include bg($colorTer);
				@include co($white);
				@include transition;
				@include hover {
					@include bg($colorSec); }

				i {
					@include mt(8px);
					@include fs(2.2);
					@include co($white);
					@include transition(.2s); } } } }
	.side-newsletter {
		h3 {
			width: 95%;
			margin: auto; }
		.btn {
			padding: 0 6px $i; } }
	.youtube,
	.facebook,
	.twitter {
		width: 100%;
		@include noScroll;
		@include bg($white); }
	.facebook {
		iframe {
			width: 294px $i;
			border: none $i; } }
	h3 {
		@include bc($color);
		@include patt(11);
		@include fs(2);
		padding: 8px 10px;

		@include co($white);
		i {
			@include mr(10px); } }
	.twitter {
		@include mt(20px); }
	.youtube {
		@include mb(20px);
		iframe {
			@include w100; } }
	.row {
		@include mb(0); }
	.panel {
		@include mb(20px); }
	article {
		@include bb(thin solid $gray-light - 30);
		@include relative;
		@include mb(30px);
		@include pb(20px);
		height: auto;
		@include w100;
		.text {
			@include font-text;
			@include fs(1.4, 150%, 300); }
		h2 {
			@include lh(150%);
			@include co($gray-dark);
			@include fs(1.7, 130%, 300); }
		.date {
			@include co($gray-dark - 10);
			i {
				@include co($gray-dark - 10); } }
		.content-image {
			padding-left: 0px; }
		figure {
			@include size(100% 200px);
			@include left;
			@include mr(20px);
			@include background;
 }			//			border: 5px solid $gray + 30

		a.btn-link {
			@include ib;
			@include fs(1.4, 150%, 300);
			@include co($color);
			@include hover {
				@include under;
				@include co($color); } }

		.icon {
			@include bot-left(-12px, 50%);
			@include ml(-24px);
			padding: 0 10px;
			@include bg(white);
			@include patt(5);
			@include block;
			@include tCenter;

			i {
				@include fs(2);
				@include co($gray + 30); } } } }

.noMargin {
	@include mb(0); }

main.news {
	@include fluid {
		article {
			height: auto;
			@include pb(60px);
			figure {
				@include tCenter;
				max-width: 100%;
				@include noFloat;
				margin: 0 0 20px; } } } }

