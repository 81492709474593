section {
	.logos {
		a {} }
	strong {
		color: $color $i; }
	.actions {
		margin-left: 20px; } }
@media screen and (max-width:992px) {
	section.banner {
		position: absolute; }
	section.video {
		.parallax {
			position: absolute $i; } } }
@media screen and (max-width:600px) {
	.mySlide {
		.caption {
			h2 {
				font-size: 3rem; }
			p {
				font-size: 2rem;
				margin: 5px 0px; } } } }
