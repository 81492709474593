//@Info

section.info {
	@include relative;
	@include bg($info-bgColor);
	@include patt($info-bgPattern);
	@include tCenter;
	margin: 0;
	padding: 15px 0 30px;
	h1 {
		@include fs(2, 100%, 300);
		@include co($gray);
		margin: 10px 0;
		@include upper;
		span {
			@include co($info-colorTitle); } }


	.text {
		@include ib;
		@include co($info-colorTxt);
		margin: 0px;
		@include fs(2.4, 150%, 300);
		@include w100; } }
