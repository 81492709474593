
@import 'myFramework/framework/positions';
@import 'myFramework/framework/snippets';
@import 'myFramework/framework/extends';
@import 'myFramework/framework/functions';
@import 'myFramework/framework/sassy';

@import 'myFramework/framework/resets';
@import 'myFramework/framework/breakpoints';
@import 'myFramework/framework/sizes';
@import 'myFramework/framework/pseudo';
@import 'myFramework/framework/helper';
@import 'myFramework/framework/flexbox';
@import 'myFramework/framework/css3';
@import 'myFramework/framework/oocss';


@import 'myFramework/theme/fonts';
@import 'myFramework/theme/colors';
@import 'myFramework/theme/variables';
@import 'myFramework/theme/layout';
@import 'settings';


@import 'smacss/base';
@import 'smacss/layout';
@import 'smacss/module';
@import 'smacss/state';
@import 'smacss/theme';

html {
	font-size: 76%; }
body {
	font-family: 'Lato', sans-serif $i;
	nav a, h2, h3, h1 {
		font-weight: 700 $i; }
	p,.text {
		font-weight: 400 $i; } }
body.font1 {
	font-family: 'Ubuntu', sans-serif $i; }
body.font2 {
	font-family: 'Lato', sans-serif $i;
	nav a, h2, h3, h1 {
		font-weight: 700 $i; }
	p,.text {
		font-weight: 400 $i;
		line-height: 24px; } }
body.font3 {
	font-family: 'Asap', sans-serif $i;
	nav a, h2, h3, h1 {
		font-weight: 700 $i; }
	p,.text {
		font-weight: 400 $i; } }
.button-collapse i {
	color: $white; }

main.workTeam, main.gallery, main.news, main.contact, main.gallery-detail, main.detail {
	margin-top: 16px $i;
	h1 {
		margin-bottom: 0px; }
	p {
		font-family: Lato,sans-serif!important; }
	.autor,.date {
		font-size: 1rem $i; }
	.autor {
		margin-bottom: 25px $i; } }
main.detail {
	h1 {
		padding-top: 10px $i; } }

main {
	.panel {
		div.p {
			padding: 20px;
			@include bc($colorSev + 20);
			@include patt(5);
			color: $white;
			@include bradius($bradius);
			line-height: 23px;
			text-align: justify; }
		div.p {
			p {
				color: $white;
				line-height: 23px;
				text-align: justify; } } } }
body {
	.btn {
		@include bradius($bradius); }
	#sidenav-overlay {
		z-index: 98; }
	#uparrow,#downarrow {
		cursor: pointer;
		width: 56px;
		height: 57px;
		position: fixed;
		bottom: 2px;
		right: 9px;
		display: none;
		@include bc($colorRed);
		color: $white;
		font-size: 4rem;
		z-index: 999999;
		padding: 10px;
		text-align: center;
		box-shadow: 0px 0px 11px $gray-dark;
		i {
			line-height: 35px; } } }

@media screen and (max-width: 1200px) {
	.headerx {
		.logo {
			width: 100%; } } }

@media screen and (min-width: 993px) and (max-width: 1200px) {
	nav {
		li {
			a {
				font-size: 1.1rem $i; } } } }
@media screen and (min-width: 993px) {
	main.gallery {}
	.distribuitors {
		.element-item {
			&:nth-child(1n + 1) {} } } }


@media screen and (max-width: 992px) {
	.headerx {
		.lang {
			position: absolute;
			right: 0;
			top: 6px; } } }


@media screen and (max-width: 800px) {
	.grid {
		text-align: center $i; }
	.distribuitors {
		.element-item {
			width: 32% $i;
			height: auto $i;
			position: relative; } } }
@media screen and (max-width: 600px) {
	section.banner {
		height: 470px;
		.mySlide {
			height: 470px; }
		.bx-wrapper {
			.bx-pager {
				bottom: 30px; } } }
	header {
		.logo {
			margin: auto $i;
			width: 100% $i;
			display: inherit $i; } }
	.distribuitors {
		.element-item {
			width: 60% $i;
			margin-right: 5% $i;
			margin: auto $i;
			left: 20% $i;
			&:nth-child(even) {
				margin-right: 0px $i; } } } }

main.workTeam, section.banner, section.highlight .news {
	.bx-wrapper {
		.bx-pager.bx-default-pager {
			a {
				width: 20px $i;
				height: 20px $i;
				border-radius: 100%;
				&.active {
					@include bc($colorQua $i); } } } } }
section, footer {
	.btn {
		@include bc($colorSix $i); } }
