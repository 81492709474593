//section.video
//	+size(100% 300px)
//	+pointer
//	video
//		height: auto $i
//		width: 100% $i

//.container
//	+b1
//	.row
//		+b2

.modal {
	@include z(9999999999999 $i); }
section.video {
	@include relative;
	@include tCenter;
	margin: 0;
	@include pointer;
	@include z(99999);

	h1 {
		@include co($white);
		@include fs(3, 130%, 400);
		@include ib;
		@include wauto;
		@include mb(20px);
		strong {
			@include block;
			@include fs(4, 130%, 600);
			color: $white $i; } }
	.text {
		@include ib;
		@include co($white);
		margin: 0px;
		@include fs(2.6, 150%, 300);
		@include w100;
		a {
			@include co($white);
			@include ib;
			margin: 0 10px 20px;
			@include under; }
		p {
			@include fs(2.4, 140%, 300);
			@include co($white); } }

	i {
		@include mt(20px);
		@include fs(8);
		@include co($white);
		@include opa(.8);
		@include borderFix; }

	.capa {
		@include bgBlack(.5);
		padding: 40px 0;
		@include transition(.2s); } }

//	+hover
//		.capa
//			+bgBlack(.5)

section.banner {
	@include top-left(100px, 0);
	position: relative;
	@include w100;
	@include z(1);
	height: 600px;
	@include mt(0px);
	.bx-wrapper .bx-controls-auto,
	.bx-wrapper .bx-pager {
		width: 50%; }
	.mousedown {
		background-image: url(../img/mouse.png);
		position: absolute;
		right: 20px;
		bottom: 20px;
		height: 70px;
		width: 70px;

		background-size: contain;
		background-repeat: no-repeat;
		background-position: center; } }

.slider {
	height: $slider-height;
	@include top-left;
	@include z(2);
	@include w100;
	.slides {
		height: $slider-height; }
	h2 {
		@include co($white);
		@include fs(3.6, 120%, 300);
		@include upper;
		@include mb(30px);
		@include w100;
		@include mt(20px); }

	p {
		@include co($white);
		@include fs(2, 150%, 100);
		@include w100;
		@include mb(30px); }


	.btn {
		@include upper;
		margin: 0 5px 10px; }

	.btn-link {
		@include block;
		@include upper;
		@include co(white);
		@include fs(1.6, 150%, 300);
		@include hover {
			@include co($colorSec); } }

	.caption {
		@include tCenter;
		top: 0px;
		height: 100%;
		.caption-container {
			@include top-right(40px, 0);
			@include size(340px auto);
			@include b3;
			@include bgBlack(.8);
			padding: 20px; }


		.caption-container.left {
			@include tLeft;
			@include left; }

		.caption-container.right {
			@include tRight;
			@include right; } }

	.indicators {
		bottom: 10px;
		text-align: $bullet-align;
		@include z(99);
		li.indicator-item {
			border: $border-bullet;
			width: $size-bullet;
			height: $size-bullet;
			margin: 0 $space-bullet;
			@include bg($color-bullet);
			@include radius(100%);
			@include transition;
			&.active {
				@include bg($color-bullet_active);
				border: $border-bullet_active; } } } }



.slider {
	@include mobil {
		h2 {
			@include fs(2.4, 120%, 700); }
		p {
			@include fs(1.6, 140%, 400); } }

	@include fluid {
		img {
			@include background(contain); } } }


.mySlide {
	height: 600px;
	@include background(cover);
	background-position: center center;
	@include bc($gray-light);
	@include before {
		@include bgBlack(.1);
		@include top-left;
		@include z(1);
		@include s100;
		@include content(''); }

	.caption {
		bottom: 83px;
		left: 34px;
		position: absolute;
		width: 45%;
		@include block;
		@include tCenter;
		@include z(9999);
		background-color: rgba(55, 49, 42, 0.7);
		.next,
		.prev {
			@include none;
			@include bot-left(10px, 50%);
			@include size(40px);
			@include radius(0);
			border: 2px solid $white;
			@include tCenter;
			@include ml(-20px);
			@include z(9999999999);
			@include transition(.2s);
			i {
				@include fs(2);
				@include mt(5px);
				@include co($white);
				@include transition(.2s); }
			a {
				@include block;
				@include size(40px);
				@include top-left; }
			@include hover {
				border: 2px solid $colorSec;
				i {
					@include co($colorSec); } } }


		.prev {
			@include top-left(10px, 50%); }

		h2 {
			@include co($white);
			@include fs(2.5, 120%, 300);
			@include mt(80px);
			@include upper;
			@include ib;
			@include wauto;
			@include tCenter;
			// +bgBlack(.5)
			padding: 8px 40px;
			@include bradius($bradius); }
		p {
			@include co($white);
			@include fs(2, 130%, 300);
			@include w100;
			@include bgColor(0);
			padding: 8px 10px;
			margin: 20px 0 0px;
			text-shadow: 0px 2px 2px $black; }
		.text {
			@include tLeft;
			@include co($white);
			@include fs(1.6, 100%, 600);
			ul li::before {
				top: 3px; } }
		.actions {
			@include noFont;
			margin: 20px 0;
			text-align: center;
			.btn {
				@include fs(1.8, 54px, 300);
				@include bc($colorSix $i); }
			.btn-link {
				@include fs(1.6, 100%, 100);
				margin: 20px 0;
				@include block;
				@include under;
				@include co($white);
				@include transition(.2s);
				@include hover {
					@include co($color); } } } } }

.banner {
	@include relative;
	.bx-wrapper .bx-pager,
	.bx-wrapper .bx-controls-auto {
		bottom: 80px; }

	.bx-wrapper .bx-pager.bx-default-pager {
		@include tCenter;
		width: 100% $i; }

	.bx-wrapper .bx-pager.bx-default-pager a {

		width: 20px;
		height: 20px;
		margin: 0 $space-bullet;
		@include bg($white);
		@include radius(100%);
		@include transition(.2s);
		@include noFont; }

	.bx-wrapper .bx-pager.bx-default-pager a:hover,
	.bx-wrapper .bx-pager.bx-default-pager a.active {
		@include bg($color); }


	.btnTer {
		@include hover {
			border: 2px solid $white $i; }
		@include after {
			@include none; } }

	.icon {
		@include size(80px);
		@include bot-right(20px, 20px);
		@include img(mouse);
		@include background(contain);
		@include z(9999);
		@include none; } }


