.social {
	@include top-right(200px, 10px);
	@include fixed;
	@include ib;
	width: auto;
	margin: 20px 2px 0 0;
	@include z(99999999999 $i);
	a {
		@include block;
		@include size(36px);
		@include bg($colorQua);
		@include tCenter;
		@include radius(100%);
		@include mb(5px);
		i {
			@include fs(1.8);
			@include co($white);
			@include mt(6px); }
		@include hover {
			@include bg($color + 30); } }
	.facebook {
		@include bg($facebook);
		@include hover {
			@include bg($facebook + 20); }
		i {
			margin-top: 8px; } }
	.twitter {
		@include bg($twitter);
		@include hover {
			@include bg($twitter + 20); }
		i {
			margin-top: 8px; } }
	.linkedin {
		@include bg($linkedin);
		@include hover {
			@include bg($linkedin + 20); } }
	.youtube {
		@include bg(#e52d27);
		@include hover {
			@include bg(#e52d27 + 20); } }
	.instagram {
		@include bg(#007bb6);
		@include hover {
			@include bg(#007bb6 + 20); } } }


//@Header

@mixin preheader-active {
	@if $preheader-active {

		@include bt(2px solid $header-colorBorder); }

	@else {} }


@mixin header-shadow {
	@if $header-shadow {
		@debug "$header-shadow is #{$header-shadow}";
		box-shadow: 0px 2px 3px rgba(80, 80, 80, .5); }

	@else {
		@debug "$header-shadow is #{$header-shadow}"; } }

header.fixed {
	@include z(99);
	@include w100;
	@include top-left;
	position: relative; }

header {
	@include relative;
	@include z(99);
	@include w100;
	box-shadow: 0px 2px 3px rgba(80, 80, 80, .5); }

.myHeader {
	@include transition(.6s);
	@include z(99999999 $i);
	@include fixed;
	@include w100; }

header.headerothers {
	.headerx {
		border-bottom: 2px solid $white; } }

.headerx {
	@include bg($color);
	@include contain;
	@include fixed;
	@include z(999);
	@include w100;
	height: 128px;
	box-shadow: 0px 2px 3px rgba(80,80,80,0.5);
	padding: 12px 0 0 0;
	.row {
		@include mb(0); }
	.logo {
		margin: 10px 0;
		@include block;
		width: 256px;
		height: 81px;
		@include background(contain);
		@include bp(center);
		background-size: 157px; }
	.lang {
		padding: 5px $i;
		background-color: rgba(0,0,0,.5);
		li {
			display: inline-block;

			a {
				img {
					width: 25px; } } } } }


//header.fixed
//	+header-shadow
//
//	+top-left()
//	+w100
//	+z(99)
//	.row
//		+mb(0)
//		.col
//			+p0
//
//
//header.noFixed
//	+relative
//	+w100
//	nav
//		+mt(40px)
//	.logo
//		height: 80px
//		margin: $space-mini 0
//	li
//		a
//			+co($color)
//			+transition(.2s)
//			+hover
//				+co($colorSec)

.menu-top {
	@include wauto;
	@include ib;
	@include mt(20px);
	@include noFont;
	.myBtn {
		@include co($white);
		height: 35px;
		@include ib;
		@include pr(10px);
		margin:  0 2px;
		@include bg($colorTer);
		@include fs(1.5, 37px, 300);
		@include transition(.2s);
		span {
			@include size(37px 35px);
			pointer-events: none;
			@include ib;
			@include bc($colorQua);
			@include mr(10px);
			@include tCenter;
			i {
				@include f(1.8rem); } }
		i {
			margin: 0 10px;
			@include f(1rem); }
		@include hover {
			@include bg($colorTer + 20); }
		@include nth1 {
			@include bg($color);
			span {
				@include size(40px 35px);
				@include bg($color + 20); }
			@include hover {
				@include bg($color + 20); } } }

	//	+tRight
	//	+noFont
	//	+bg($gray-light - 10)
	//	+wauto
	//	+ib
	//	+mt(10px)
	//	padding: 10px 10px
	//
	//	.btn-link
	//		+fs(1.5, 100%, 300)
	//		+co($gray - 20)
	//		+ib
	//		padding: 0 15px
	//		margin: 0
	//		+transition(.2s)
	//		i
	//			+mr(8px)
	//		+nth1
	//			+br(thin dotted $gray - 10)
	//		+nth2
	//			+br(thin dotted $gray - 10)
	//		+hover
 }	//			+co($color)


.headerx {
	.slogan {
		@include ib;
		@include va;
		@include tLeft;
		@include mt(18px);
		@include ml(20px);
		@include fs(2.2, 100%, 700);
		@include co($color);
		span {
			@include block;
			@include fw(300); } }



	.actions {
		@include noFont;
		margin: 12px -13px 10px 0;
		.myBtn {
			@include co($white);
			height: 35px;
			@include ib;
			@include pr(10px);
			margin:  0 5px;
			@include bg($colorTer);
			@include fs(1.5, 37px, 300);
			@include transition(.2s);
			span {
				@include size(37px 35px);
				pointer-events: none;
				@include ib;
				@include bc($colorQua);
				@include mr(10px);
				i {
					@include f(1.8rem); } }
			i {
				margin: 0 10px;
				@include f(1rem); }
			@include hover {
				@include bg($colorTer + 20); }
			@include nth2 {
				@include bg($color);
				span {
					@include size(40px 35px);
					@include bg($color + 20); }
				@include hover {
					@include bg($color + 20); } } } } }


.fix {
	position: relative;
	height: 110px;
	display: block;
	width: 100%; }

