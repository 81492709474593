//@titles



main {
	h1 {
		@include co($gray-dark);
		@include fs(2.8, 100%, 300);
		@include upper;
		@include mb(20px);
		@include pt(20px);
		strong {
			@include co($color);
			@include fs(2.8, 100%, 300); } } }


