//@import url(http://fonts.googleapis.com/css?family=Roboto:400,100,300,500,700,900)
//@import url(http://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700,800)

@mixin font-site {
	@include font; }

@mixin font-text {
	@include font; }

//FONTS

@mixin font {
	font-family: $font1;
	@include legible; }


@mixin legible {
	text-rendering: optimizelegibility; }

$font: 'Roboto', sans-serif;
$font1: 'Open Sans', sans-serif;




