

//@AboutUs

$tab-color_active:  $color;
$tab-color: $colorSec;

$border-active: true !default;

@mixin border-active {
	@if $border-active {
		border: 4px solid $gray-light; }

	@else {} }




main.aboutUs {

	figure {
		margin: 0 0 $space;
		@include size(100% 300px);
		@include border-active;
		@include figure; }

	.row {
		@include mb(0); }

	.tabs {
		height: 30px $i;
		@include bg($transparent $i);
		@include mb(20px);

		li {
			height: auto $i;
			@include bg($transparent $i);
			a {
				@include fs(1.6, 100%, 600);
				@include upper;
				@include co($tab-color $i); }
			.active {
				@include co($tab-color_active $i); } } }


	.tabs .indicator {
		@include bg($tab-color_active $i); }

	.tab-content {
		@include mb($space);
		h2 {
			@include fs(2, 100%, 700);
			@include mb($space); } } }



.workTeam {
	.panel {
		margin: 0 0 10px;
		padding: 0; }

	.grid {
		padding-left: 20px $i; }
	.row {
		@include mb(0);

		img {
			@include ib;
			@include centered;
			@include mb(10px);
			border: 4px solid $colorSec; }

		h4 {
			@include fs(2, 100%, 400);
			@include mb(20px);
			@include co($gray-dark); }

		.text {
			@include pb(20px);
			@include relative;
			@include tLeft;
			@include fs(1.4, 150%, 300);
			@include before {
				@include content('');
				@include ib;
				@include va;
				@include bot-left(0, 50%);
				@include size(10px 10px);
				@include bc($color);
				@include radius(100%);
				@include ml(-5px); } } }

	h3 {
		margin: 0px 0;
		padding: 10px 20px;
		@include fs(2, 100%, 400);
		@include upper;
		@include bg($color);
		@include co(white);
		@include bradius($bradius); }
	.text {
		@include mb(20px);
		ul {
			li {
				position: relative;
				@include pl(30px);
				&:before {
					content: "\f111";
					font-family: FontAwesome;
					font-style: normal;
					font-weight: normal;
					text-decoration: inherit;
					color: $colorRed;
					font-size: 17px;
					padding-right: 0.5em;
					position: absolute;
					top: 10px;
					left: 0;
					font-weight: bold; }
				color: $color; } } }
	article {
		@include bb(thin solid $gray-light);
		margin: 20px 0;
		@include bb(thin solid $gray-light - 20);
		@include pb(0px);
		@include mb(0px);
		@include relative;
		h2 {
			@include fs(2, 100%, 400);
			@include co($gray-dark);
			margin: 0px 0 10px;
			@include upper; }

		.position {
			padding: 0px 0;
			@include co($color);
			@include mb(2px);
			@include mr(20px);
			@include bt(thin dotted $gray-light - 20);
			@include bb(thin dotted $gray-light - 20);
			i {
				@include mr(5px); } }
		.mail {
			@include block;
			@include bt(thin dotted $gray-light - 20);
			padding: 0px 0 8px;
			@include mr(20px);
			@include co($color);
			i {
				@include mr(5px); } }

		.text {
			@include mt(10px);
			@include fs(1.4, 130%, 300); }

		figure {
			@include left;
			width: 120px;
			height: 150px;
			@include mb(20px);
			@include mr(10px);
			@include background;
			border: 4px solid $gray;
			@include radius(6px); }

		.icon {
			@include none;
			@include bot-left(-20px,50%);
			margin: 0 0 0 -30px;
			@include bg(white);
			@include tCenter;
			@include size(40px 40px);
			span {
				@include img(logotipo-gray);
				@include background(contain);
				@include opa(.6);
				@include block;
				@include grayFilter(1);
				@include size(40px 30px); } } }
	.logo {
		@include mt(20px);
		@include tCenter;
		@include block;
		img {
			height: 40px;
			@include ib;
			@include mb(10px); }
		a {
			@include block;
			@include fs(1.4, 120%, 300);
			@include co($color);
			@include hover {
				@include co($color - 20);
				@include under; } } } }
.team {
	.left {
		display: inline-block;
		.fa {
			float: left;
			margin-right: 5px; } } }
.workTeam .banneraboutus {
	background-size: cover;
	width: 100%;
	height: 250px;
	@include bradius($bradius); }

.text-team {
	p {
		margin-bottom: 10px!important; }
	h2 {
		margin-bottom: 20px 0px!important;
		padding: 15px 0px; } }

.workTeam {
	.button-group {
		margin-top: 10px; }

	.quantity-title {
		h2 {
			font-size: 2.8rem;
			padding: 20px 0px;
			@include mb(10px);
			font-weight: 300 $i;
			color: $gray-dark;
			strong {
				color: $color; } } }
	.quantity {
		background-color: $gray + 70 $i;
		padding: 8px 0px;
		border-bottom: 3px solid $color;

		h3 {
			margin: 5px 0 $i;
			@include bc(transparent);
			color: $color; }
		.white-text {
			padding: 10px;
			text-align: center;
			color: $gray-dark $i; }
		span.value {
			font-size: 3rem;
			text-align: center;
			display: inherit;
			@include bc($color - 50);
			width: 100%;
			height: 50px;
			padding: 16px;
			color: $white; } }
	.quantity:before {
		content: "\f1fe";
		font-family: FontAwesome;
		font-style: normal;
		font-weight: normal;
		text-decoration: inherit;
		color: $gray-dark + 50;
		font-size: 41px;
		padding-right: 0.5em;
		position: absolute;
		top: 11%;
		left: 83%;
		opacity: .3; }
	.myPanel {
		margin-bottom: 0 $i; } }
