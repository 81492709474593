
//@Products

main.products {
	section.banner {
		position: relative;
		top: 0;
		.mySlide {
			@include background;
			&:before {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				background-color: rgba(0,0,0,.2);
				left: 0; }
			.relative {
				right: 0px;
				width: 100%;
				height: 100%; }
			.caption {
				top: 20%;
				h2 {
					@include bc(transparent);
					text-shadow: 0px 2px 10px #000000; } } } }
	.card-reveal {
		@include font-text;
		i {
			@include co($colorSec); } }
	.row {
		@include mb(0); }
	.panel {
		@include mb(20px); }
	article {
		@include hover {
			figure {
				i {
					@include co($color); } } }

		p {
			@include mt(20px); }
		.btn {
			@include co(white);

			@include f(1.2rem); }

		h2 {
			@include fs(1.6, 130%, 700);
			@include m0;
			@include co($gray-dark);
			@include pointer;
			@include transition(.2s);
			@include hover {
				@include co($colorSec); }

			span {
				@include ib;
				width: 88%;
				@include va; }

			i {
				@include ib;
				@include va; } } }

	.card-action a {
		@include block;
		margin: 0 auto;
		@include tCenter;
		//		+after
		//			+content('')
		//			+size(4px 100%)
		//			+bg($colorSec)
		//			+block
		//			+top-right(0, 4px)
		//		+before
		//			+content('')
		//			+size(4px 100%)
		//			+bg($colorSec)
		//			+block
 }		//			+top-right(0, 10px)



	figure {
		@include w100;
		@include tCenter;
		@include background;
		height: 160px;
		@include relative;
		@include pointer;
		i {
			@include transition; } }

	.price {
		@include bg($colorSec);
		padding: 10px;
		@include top-right(20px, 0);
		@include none;
		@include white;
		@include radius-custom(5px, 0, 0, 5px);
		i {
			@include co($white $i); } }


	.card-action {
		@include tCenter;
		padding: 10px $i; }


	.card-title {
		@include lh(100%); }

	i {
		@include pointer; }

	.btn {
		@include borderFix;
		i {
			@include co(white);
			@include borderFix; } }

	.card-content {
		padding: 10px $i; } }


main.products.categories {
	ul.list-group {
		@include mb(20px);
		@include mt(5px);
		> li {
			@include bb(1px solid $gray-light);

			@include transition;
			> a {
				@include block;
				padding: 15px 10px;
				@include co($gray-dark);
				@include bg($gray-light + 20);
				@include transition(.2s);
				@include fs(1.4, 100%, 400);
				@include hover {
					@include bg($colorSec);
					@include white;
					@include before {
						@include white; } } }

			> ul {
				@include none; } }

		> .class_active {
			margin: 0 $i;
			> a {
				@include bg($colorSec);
				@include white;
				@include before {
					@include white; } } } } }


main.products.categories {
	ul.list-group {
		@include bg($gray-light);
		> li {
			a {
				@include before {
					font-family: fontAwesome;
					color: $gray-dark;
					@include mr(10px);
					content: '\f054'; }
				i {
					@include none; } }

			@include nth1 {
				a {
					@include before {
						content: '\f0ae'; } } } }


		.ico-more {
			@include none; } } }
