//@carrousel

.carousel2 {
	@include bc($color);
	@include noScroll;
	@include patt(4);
	padding: 40px 0 30px;
	@include m0;
	@include w100;
	@include relative;
	@include tLeft;
	@include z(999);
	h2 {
		@include co($gray-dark);
		@include mb(30px);
		@include ib;
		@include w100;
		@include upper;
		@include tCenter;
		@include fs(3, 100%, 300);
		strong {
			color: $white $i;
			@include fs(3, 100%, 300); } }
	h3 {
		margin: 10px 0 0;
		@include fs(1.6, 100%, 300);
		@include tCenter;
		@include co($carousel-colorSubtitle);
		@include tRight; }
	.text {
		@include fs(2, 140%, 100);
		@include co($white);
		@include bb(thin dotted $white);
		@include tLeft;
		@include pb(10px);
		i {
			margin: 0 10px;
			@include fs(2); } } }

section.carousel {
	.text ul li::before {
		@include co($color $i); }
	background-color: $grayTer;
	@include noScroll;
	padding: 40px 0 30px;
	@include m0;
	@include w100;
	@include relative;
	@include tLeft;
	@include z(999);
	figure {
		@include tCenter;
		@include size(120px);
		@include centered; }
	img {
		@include size(80px);
		@include transition(.4s);
		@include mt(15px $i);
		@include ib;
		filter: grayscale(1);
		@include hover {
			@include grayFilter(0); } }

	i {
		@include fs(12);
		@include co($white);
		@include opa(.4); }

	h2 {
		margin: 15px 0 59px;
		vertical-align: top;
		width: 100%;
		text-align: center;
		line-height: 100%;
		font-size: 2rem;
		color: #fffffa; }

	h3 {
		margin: 10px 0 0;
		@include fs(2, 100%, 400);
		@include tCenter;
		@include co($white);
		display: none; }

	.text {
		@include fs(1.5, 140%, 300);
		@include co($white);
		@include bb(thin solid $white);
		@include tLeft;
		@include pb(10px);
		i {
			margin: 0 10px; } }

	.bx-wrapper .bx-pager, .bx-wrapper .bx-controls-auto {
		bottom: -40px $i;
		@include none; }

	.bx-wrapper .bx-pager.bx-default-pager a {
		@include bg($carousel-bgBullet $i);
		@include size($carousel-sizeBullet);
		@include radius(100%);
		margin: 0 $carousel-spaceBullet $i;
		border: $carousel-borderBullet;
		@include transition; }
	.bx-wrapper .bx-pager.bx-default-pager a.active {
		@include bg($carousel-bgBullet_active $i);
		border: $carousel-borderBullet_active; }


	.container-responsive {
		width: 100%;
		@include centered; }
	strong {
		color: $color $i; } }
//section.carousel
//	+mobil
//		.container-responsive
//			width: 70%
//			+b2
main.workTeam {
	.bx-wrapper {
		margin-top: 15px;
		.slideritemscontent {
			margin-top: 10px;
			.slide__iconslide {
				position: absolute;
				top: 0px;
				left: 0px;
				width: 100%;
				@include tCenter;
				font-size: 60px;
				color: $colorRed;
				z-index: 999;
				opacity: .9; }
			.bx-prev {
				@include after {
					content: "\f053";
					font-family: FontAwesome;
					font-style: normal;
					font-weight: normal;
					text-decoration: inherit;
					color: #000;
					font-size: 18px;
					padding-right: 0.5em;
					position: absolute;
					top: 10px;
					left: 0; } }
			.slide {
				@include bradius($bradius);
				figure {
					@include bradius($bradius);
					width: 100%;
					height: 200px;
					border: 0px;
					@include radius(0);
					background-size: cover;
					background-position: center;
					position: relative;
					figcaption {
						@include bradius($bradius);
						display: table;
						padding: 20px;
						position: absolute;
						left: 0;
						@include size(100%);
						background-color: rgba(75,89,180,.2);
						box-shadow: 4px 3px 0px $gray-dark;
						p {
							color: white;
							display: table-cell;
							vertical-align: middle;
							text-shadow: 0px 1px 10px $black; } } }
				&:nth-child(2n+0) {
					figure {
						figcaption {
							background-color: rgba(39,193,188,.2) $i; } } }
				&:nth-child(3n+0) {
					figure {
						figcaption {
							background-color: rgba(247,191,0,.2) $i; } } } } } } }
section.carousel {
	h3 {
		@include bc(transparent $i);
		color: $color; } }
