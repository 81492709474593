@mixin prefooter-active {
	@if $prefooter-active {

		@include bb(4px solid $footer-colorBorder); }

	@else {} }


footer.fixed {
	@include bot-left; }

footer {
	.logo {
		@include mt(9px!important); }
	@include relative;
	@include z(999);
	@include tCenter;
	.actions {
		a {
			@include ib;
			@include va;
			margin: 0 20px; }
		img {
			@include ib;
			height: 40px;
			@include opa(.8);
			@include transition;
			margin: 20px 0 0;
			@include hover {
				@include opa(1); } } }


	.social {
		@include tCenter;
		margin: 20px 0;
		a {
			@include ib;
			@include tCenter;
			@include size(30px);
			@include radius(5px);
			margin: 0 0 0 2px;
			@include bg($white);
			@include transition;
			@include hover {
				@include bg($colorTer);

				i {
					@include white; } }
			i {
				@include mt(5px);
				@include co($colorTer);
				@include fs(2);
				@include transition(); } } }
	@include w100;

	@include z(999);
	@include bt(1px solid white);
	@include co($white);
	@include prefooter-active;
	@include patt($footer-bgPattern);
	@include bc($color);
	//	+gradient(top,$colorSec, 50%, $color, 99%)
	@include fs(1.4, 100%, 400);
	.text {
		@include co($gray-light + 20);
		@include fs(1.4, 150%, 300); }

	.capa {
		@include bg($color - 20);

		padding: 10px 0;
		.row {
			@include mb(0); } }
	a {
		@include co($white); }
	.row {
		@include mb(0); }
	figure {
		margin: 20px 0;
		@include w100;
		@include tCenter;
		img {
			height: 80px;
			@include opa(.6); } }
	nav {
		margin: 45px 0 0;
		@include bl(thin solid $white);
		ul {
			@include w100;
			@include tCenter;
			li {
				@include block;
				margin: 0 0px;
				a {
					padding: 10px 0px 10px 20px;
					@include tLeft;
					@include upper;
					@include block;
					@include transition;
					@include co($footer-anchorColor);
					@include hover {
						@include co($footer-anchorColor_hover); } } }

			.last {
				@include noBorder; } } }


	.contact-info-int {
		@include w100;
		margin: 20px 0 10px;
		@include fs(1.5, 100%, 400);
		@include tCenter;
		> div {
			@include ib;
			margin: 5px 0px;
			margin-right: 3px;
			font-size: 1.3rem;
			i {
				@include mr(5px); }
			a {
				@include co($white);
				@include under;
				@include hover {
					@include co($white); } } } } }




.postFooter .ahorranito,
.postFooter.copyright {
	@include fluid {
		@include mb(10px);
		@include tCenter;
		@include lh(120%); } }

.postFooter nav ul li a,
.postFooter .contact-info > div {
	@include mobil {
		@include lh(150%); } }

.ahorranito {
	span {
		@include co(#1d9ad4); }

	.ahorranito-icon {
		@include img(ahorranito);
		background-position: center;
		background-repeat: no-repeat;
		display: inline-block;
		height: 20px;
		vertical-align: middle;
		width: 20px; } }

.postFooter {
	padding: 15px 0 15px;
	@include bc($color);
	@include relative;
	@include z(999);
	@include fs(1.3, 100%, 300);
	.row {
		@include mb(0); }
	.copyright {
		@include mt(5px);
		@include block;
		@include co($white); }
	.ahorranito {
		@include tRight;
		@include mt(3px);
		a {
			@include ib;
			@include co($white);
			@include va; }
		.ahorranito-icon {
			@include ib;
			@include va; } } }
footer {

	.contact-info {
		padding-left: 0 $i;
		.left {
			margin-left: 0px; }
		.footersocial {
			margin-bottom: 10px;
			text-align: left;
			padding-left: 0;
			a {
				font-size: 2.6rem;
				margin: 0 0px;
				color: $graySec $i;
				width: 200px;
				height: 66px;
				float: left;
				background-repeat: no-repeat;
				background-size: contain; }

			a.firsticon {
				margin-left: 0px $i; } } }
	.row {
		.imgcertify {
			height: 75px!important;
			margin-top: 6px;
			margin-right: 20px; }
		.imgcertify:nth-of-type(1) {
			margin-right: 0px; } }
	.container-newsletter {
		form {
			padding: 0px 12px; }
		@include bc($colorTer);
		@include patt(5);
		padding: 15px 0px;
		input {
			color: $color; }
		h3 {
			text-align: left;
			@include fs(2.6, 100%, 300);
			color: $color;
			padding-top: 4%;
			@include before {
					@include content("");
					@include bc($color);
					width: 2px;
					display: inline-block;
					height: 22px;
					padding-left: 3px;
					margin-right: 6px; } }
		.btn {
			margin-right: 12px; } } }
.postFooter {
	.copyright {
		padding-left: 0px $i; } }



// FOOTER--------------------------------------------------

footer {
	.container {
		width: 90%; }
	.footer-sec {
		margin: 90px 0;
		height: 200px;
		padding-left: 30px;
		h2 {
			text-align: left;
			font-size: 1.8rem;
			margin-bottom: 30px; }
		p {
			text-align: left;
			color: #737373;
			margin-bottom: 10px; }
		div.date-not {
			text-align: left;
			font-size: 1.5rem;
			margin-bottom: 30px;
			font-weight: 300; }
		ul {
			text-align: left;
			li {
				padding: 10px 0;
				a {
					line-height: 20px;
					color: #737373;
					i {
						color: $white; } } } } }
	.footer-ter {
		ul {
			li {
				display: inline-block;
				margin: 0;
				width: 30px;
				margin-right: 10px;
				background-color: $white;
				border-radius: 100%;
				width: 35px;
				height: 35px;
				text-align: center;
				a {
					margin-top: 5px;


					i {
						color: #737373;
						font-size: 1.7rem; } } } }
		figure {
			width: 100%;
			margin-top: 100px;
			img {
				margin-right: 100%; } } } }
