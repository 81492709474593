
*, *:before, *:after {
	@include cero;
	@include sizing; }



//@Pisados

.btn-floating {
	@include bg($color $i); }

.btn,
.btn-large {
	@include bg($color);
	@include radius(0 $i);
	@include white;
	@include bb(2p solid $colorSec);
	@include hover {
		@include bg($color - 20);
		@include white; }
	@include focus {
		@include bg($color - 40);
		@include white; } }
.btnSec {
	@extend .btn;
	@include bg($colorSec);
	@include white;
	@include hover {
		@include bg($colorSec - 20);
		@include white; }
	@include focus {
		@include bg($colorSec - 40);
		@include white; } }

.btnTer {
	@extend .btn;
	@include bg(transparent);
	border: 2px solid $colorSec $i;
	@include white;
	@include relative;
	@include hover {
		@include bg(transparent);
		border: 2px solid $colorSec + 20 $i;
		@include white;
		@include after {
			@include size(110%); } }

	@include focus {
		@include bg(transparent);
		border: 2px solid $colorSec + 40 $i;
		@include white; }
	@include after {
		@include content('');
		@include block;

		@include top-left;
		@include transition(.4s);
		@include z(-1);
		@include size(0 100%);
		@include bg($colorSec + 20); } }





select,
input[type="text"],
input[type="password"],
textarea {
	font-size: 1.3rem $i;
	@include co($gray $i);
	@include font; }



//general

.text {
	ul {
		@include mt(10px);
		li {
			padding: 0 0 0 16px;
			@include relative;
			@include fw(300);
			@include mb(10px);
			@include before {
				font-family: fontAwesome;
				color: $colorSec;
				@include fs(1);
				@include top-left(6px, 0);
				content: '\f1ce';
				@include rotate(90); } } } }



@mixin anchor {
	color: $anchor-color;
	@include transition($anchor-transition);
	@include noUnder;
	@include fs($anchor-sizeFont, 100%, 400);
	@include hover {
		@include co($anchor-colorActive); } }

main a {
	@include anchor; }


ul,
ol {
	@include m0; }

p {
	@include para; }


@mixin page {
	@include relative;
	width: 70%;
	@include centered;
	@include fluid {
		width: 90%; } }

.wrapper {
	@include page; }



html {
	font-size: 62.5%; }

body,
hmtl {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	overflow-x: hidden;
	@include font-site;
	@include fs(1.3, 100%, 400);
	@include co($gray-dark); }

*:focus {
	outline: none;
	border: none;
	box-shadow: none; }

p {

	@include mb(0px);
	@include lh(130%);
	@include last {
		@include mb(0); } }

.btn-link {
	@include co($gray-dark);
	@include fs(1.4, 100%, 500);
	@include transition();
	@include mt(10px);
	@include hover {
		@include noUnder;
		@include co($color); } }




//.preload
//	bottom: 0
//	height: 100%
//	left: 0
//	position: fixed
//	right: 0
//	top: 0
//	width: 100%
//	z-index: 99999999999
//	+bg(white url(#{$img}loading.gif) center center $nr)
//	+fluid
//		background-size: 50%


///	+bg(white url(../img/logotipo.png) center center $nr)


.text {
	@include para; }

nav ul {
	@include noList; }


.bx-wrapper {
	@include mb(0); }

.bx-viewport {
	@include noBorder;
	@include noShadow; }


.btn.return {
	@include right;
	@include mb(20px); }



//@Alerts

.alert {
	@include page;
	@include fs(1.3, 130%, 300);
	@include white;
	padding: 10px;
	@include bgWhite;
	@include radius(4px);
	margin: 10px 0;
	border: thin solid $gray-light;
	p {
		@include co($in);
		@include fs($in, $in, $in);
		@include ib;
		font-size: $in;
		vertical-align: middle; }
	i {
		@include mr(10px);
		@include fs(3);
		@include ib;
		vertical-align: middle; } }


.alert-error {
	@extend .alert;
	@include bg($error);
	border: 2px solid $error - 10;
	i {
		@include co($error - 30); } }


.alert-success {
	@extend .alert;
	@include bg($success);
	border: 2px solid $success - 10;
	i {
		@include co($success - 30); } }



//figure
//	+fluid
//		+noScroll
//		max-width: 100% $i
//		height: auto $i
//		img
//			max-width: 100% $i
//			height: auto $i

img {
	max-width: 100%;
	height: auto; }





section.social {
	@include top-right(220px, 10px);
	@include fixed;
	@include z(9999999);
	a {
		@include size(30px);
		@include radius(100%);
		@include bg($colorSec);
		@include tCenter;
		@include mb(5px);
		@include block;
		border: 2px solid $gray-light;
		@include transition();
		@include hover {
			@include bg($color);
			border: 2px solid $color; }
		i {
			@include white;
			@include fs(1.6);
			@include ib;
			vertical-align: middle;
			@include mt(5px); } } }



.return {
	@include mb(20px); }





main.error-page {
	@include tCenter;
	h1 {
		@include white;
		@include fs(4, 150%, 700);
		margin: 20px 0;
		@include upper; }
	h2, a {
		@include fs(3, 150%, 700);
		@include gray-dark; }
	a {
		@include under; } }



article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block; }




//@Date

@mixin date {
	@include co($color-txt);
	@include w100;
	@include fs(1.3, 100%, 300);
	margin: $space-mini 0;
	i {
		@include co($color-txt);
		@include mr(5px); } }


.date,
.autor {
	@include date; }

a {
	@include transition($anchor-transition); }

main {
	@include bg($gray-light + 20); }
