main.gallery {
	.distribuitors {
		.grid2 {
			position: relative;
			text-align center $i {}
			line-height: 30px;
			.element-item {
				width: 32%;
				height: auto;
				display: inline-block;
				margin-right: 5px $i;
				margin-bottom: 25px $i;
				line-height: 20px;
				margin-top: 0px;
				.card-image {
					a {
						margin: 0px; } }
				.text {
					height: 100%;
					font-size: 1.3rem; }
				.card-action {
					a {
						margin-right: 0px; } }
				a {
					i {
						margin-left: 1px;
						line-height: 36px; } }
				.card-title {
					color: $color;
					text-align: center;
					width: 100%; }
				> div {
					height: auto $i; } } }
		figure {
			height: 200px;
			border-radius: 3px 3px 0px 0px $i; } } }



main.gallery-detail {
	article {
		border-bottom: thin solid #e1e1e1;
		border-radius: 6px;
		a {

			display: inherit;
			img {
				height: 170px;
				border-radius: 6px;
				margin: auto;
				display: inherit;
				border: 1px solid $gray; } }
		h3 {
			margin: 10px;
			font-size: 1.2rem;
			line-height: 110%;
			font-weight: 400;
			text-transform: uppercase;
			text-align: center; } }
	h1 {
		margin-bottom: 0;
		float: left;
		padding: 20px 0px; }
	actions {
		margin-bottom: 20px; } }

.gallery {
	button {
		width: 100%; }
	.panel {
		padding: 0px $i; }
	h1 {
		padding-left: 0px;
		padding-right: 15px; } }
.distribuitors {
	.button-group {
		margin-top: 0px;
		button {
			word-wrap: break-word;
			margin-left: 0px $i; } } }

main.gallery {
	.distribuitors {
		.element-item {
			h3 {
				font-size: 1.3rem $i;
				word-wrap: break-word; } } } }
