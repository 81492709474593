.myPanel {
	@include mb(20px);
	@include fs(1.5, 150%, 300);
	.container {
		@include pb(20px);
		@include bb(thin solid $gray-light - 20); } }

main {
	@include bc(white);
	@include mt(0px); }

.panel {
	margin: 0;
	padding: 20px 0;
	@include block;
	@include fs(1.7, 150%, 400);
	@include bg($gray-light - 5); }


//@Home

section.areas, section.highlight, main.news article {
	a {
		&:hover {
			color: $color + 20 $i;
			i {
				color: $color + 20 $i; } } }
	.icon {
		i {
			color: $colorQua $i; } } }


section.logos {
	.container {
		width: 80% $i; }
	margin: 30px 0 0 0;
	padding: 30px 0;
	@include relative;
	@include z(999);
	.logos2 {
		text-align: justify;
		.log-inf {
			padding: 0 10px;
			display: inline-block;
			vertical-align: top;
			float: left;
			width: 24%;
			height: 228px;
			background: $colorQua;
			border-right: 1px solid white;
			border-left: 1px solid white;
			&:hover {
				background-color: $colorSec; }
			h2 {
				color: $white;
				font-size: 1.5rem;
				text-align: center;
				font-weight: 100 $i;
				line-height: 28px;
				text-transform: uppercase; }

			.log-table {
				width: 100%;
				height: 100%;
				display: table;
				.log-cell {
					display: table-cell;
					vertical-align: middle;
					figure {
						margin-bottom: 20px;
						img {
							margin: auto;
							display: inherit; } } } } } }






	// img
	// 	+ib
	// 	+va
	// 	margin: 0 30px 0px
	// 	height: 94px $i
	// 	+grayFilter(0)
	// 	+transition(.6s)
	// 	+pointer
	// .text
	// 	+fs(1.8, 130%, 300)
	// 	+co($gray-dark)
	// 	+ml(10px)
	// 	width: 50%
	// 	+ib
	// 	+tLeft
	// +hover
	// 	img
	// 		+grayFilter(0)
	// a
	// 	+ib
	// 	+va
	// 	+fs(2, 100%, 300)
	// 	+co($gray-dark)
	// 	+mt(0px)
	.actions {
		@include ib;
		@include va;
		@include tCenter;
		width: 80%;
		margin-left: 0px $i;
		a {
			@include block;
			@include co($colorRed);
			width: auto;
			display: inline-block;
			&:nth-of-type(1) {
				margin-right: 10%; }

			@include fs(1.8, 100%, bold);
			margin: 0 0 5px;
			@include hover {
				@include co($colorRed - 30); }
			i {
				@include mr(7px);
				@include ib;
				@include va;
				@include mt(-3px);
				@include fs(.8);
				@include co($color); } } } }



section.quotes {
	@include relative;
	@include bg($gray-light - 10);
	@include tCenter;
	@include noScroll;
	padding: 30px 0;
	.text {
		@include fs(2.2, 160%, 100);
		@include co($gray - 30);
		@include tCenter;
		@include noScroll;
		@include relative;
		padding: 0 100px;
		@include z(9); }
	.icon {
		@include bot-left(50%, 15%);
		@include size(80px);
		margin: 0 0 -40px 0;
		@include z(1);
		i {
			@include fs(8);
			@include co($gray-light - 20); } }

	.icon2 {
		@include bot-right(50%, 15%);
		@include size(80px);
		@include rotate(180);
		margin: 0 0 -40px 0;
		@include z(1);
		i {
			@include fs(8);
			@include co($gray-light - 20); } } }

.videosGallery {
	.item-gallery {
		@include relative;
		@include tCenter;
		padding: 10px;
		@include w100;
		@include bg($gray-light);
		.icon {
			@include top-left(50%, 50%);
			@include size(60px);
			@include tCenter;
			@include radius(100%);
			margin: -30px 0 0 -30px;
			pointer-events: none;
			i {
				@include f(5rem);
				@include co($white);
				@include mt(15px);
				@include ml(10px); } } }

	.big {
		padding: 20px;
		@include mb(10px);
		.icon {
			@include top-left(50%, 50%);
			@include size(80px);
			@include tCenter;
			@include bg($color);
			@include radius(100%);
			margin: -40px 0 0 -40px;
			pointer-events: none;
			i {
				@include f(5rem);
				@include co($white);
				@include mt(15px);
				@include ml(10px); } } } }


.modalx {
	h2 {
		@include co($color);
		@include upper;
		@include mb(20px);
		@include fs(2.6, 100%, 500); }
	.modal-close {
		@include top-right(10px, 10px); }
	.steps {
		@include tCenter;
		@include mt(20px);
		.step {
			padding: 5px 0;
			@include bb(thin solid $gray);
			.number {

				@include radius(100%);
				@include pt(17px);
				@include ib;
				@include size(50px);
				@include co($white);
				@include patt(5);
				@include f(2rem);
				@include mr(10px); }

			.text {
				@include ib;
				width: 90%;
				@include tLeft; }
			@include nth1 {
				.number {
					@include bc($color + 60); } }
			@include nth2 {
				.number {
					@include bc($color + 50); } }
			@include nth3 {
				.number {
					@include bc($color + 40); } }
			@include nth4 {
				.number {
					@include bc($color + 30); } }

			@include nth5 {
				.number {
					@include bc($color + 20); } }

			@include nth5 {
				.number {
					@include bc($color + 10); } } } } }


main.trip {
	figure {
		height: 300px;
		@include w100;
		@include background;
		border: 4px solid $gray-light;
		@include mb(20px); } }

section.numbers {
	height: 170px;

	@include noScroll;
	@include bc($colorSec);
	@include patt(5);
	@include co($white);
	.otherSlide {
		height: 120px;
		.number {
			@include fs(7, 100%, 400);
			@include mt(20px);
			@include ib;
			@include co(white); }
		.data {
			@include fs(4, 100%, 400);
			@include ib;
			@include relative;
			@include mt(40px);
			@include ml(20px);
			@include z(9); }
		.otherNumber {
			@include fs(12, 100%, 400);
			@include bot-right(0px, 10%);
			@include opa(.2);
			@include z(8); } }
	h2 {
		@include tCenter;
		@include fs(3.2, 100%, 400);
		@include pt(20px);
		@include block;
		@include co($colorTer + 10); } }


main.askUs {
	@include background;
	@include relative;
	height: 580px;
	figure {
		height: 500px;
		@include background(contain);
		background-position: right bottom; }
	.consultant {
		@include top-right;
		@include size(400px 100%);
		@include opa(0);
		@include transition(.4s);
		.caption {
			@include pb(20px);
			@include bb(thin solid $gray);
			@include tRight;
			@include top-right(60px, 280px);
			width: 220px;
			h2 {
				@include mb(20px); }
			.text {
				@include font-text; } } }


	.form {
		@include top-left(50px, 0);
		@include radius(4px);
		padding: 20px;
		width: 400px;
		height: 320px;
		@include bgBlack(.8);
		@include none;
		@include co($white);
		h2 {
			@include mb(20px);
			@include co($white); }
		.text {
			@include co($white);
			@include font-text; }
		textarea {
			margin: 20px 0 0;
			@include radius(4px);
			resize: none;
			height: 100px;
			@include transition(.4s);
			@include co(white $i);
			@include font-text;
			padding: 5px;
			border: thin solid $white;
			@include focus {
				border: thin solid $colorTer; } }
		.btn {
			@include bg($blue);
			@include mt(20px); }
		label {
			@include mb(10px);
			@include block; }
		select {
			@include block;
			@include mb(20px); } }

	.consultant-active {
		@include opa(1);
		@include transition(.4s); }

	.form-active {
		@include block; } }

main.process {
	.help {
		@include bg($gray-light);
		@include mt(20px);
		border: thin solid $gray-light - 10;
		padding: 8px 10px;
		@include fs(1.4, 130%, 300); }

	.status {
		@include co($colorQua); }
	.btn-link {
		@include fs(1.4, 100%, 300);
		@include co($color);
		@include hover {
			@include co($color - 20); } }
	.row {
		@include mb(0); }
	.panel {
		@include tLeft;
		.icon {
			@include size(40px);
			@include ib;
			@include mt(5px);
			@include mr(20px);
			@include radius(100%);
			@include bg($color);
			@include tCenter;
			i {
				@include co($white);
				@include fs(3);
				@include mt(5px); } }
		p {
			@include ib;
			width: 84%;
			@include fs(1.6, 150%, 300);
			@include tLeft; }
		span {
			@include co($color);
			@include fw(700); } }

	.filters {
		margin: 20px 0;
		@include bg($gray-light);
		@include pb(20px);
		.input-field {
			margin: 5px 20px; }
		.btn {
			@include mr(20px); }
		h2 {
			@include bg($color);
			@include co($white);
			padding: 10px;
			i {
				@include fs(2);
				@include mr(10px); } }
		select {
			height: 35px;
			@include bb(1px solid $color);
			@include mb(20px); }
		.text {
			margin: 10px 20px 20px;
			@include pb(10px);
			@include fs(1.4, 130%, 300);
			@include bb(thin solid $gray-light - 20); } }


	table {
		@include mt(20px);
		@include mb(60px);
		@include bg(white);
		thead {
			@include bg($color);
			@include co($white);

			tr {
				th {
					@include radius(0);
					@include fs(1.8, 100%, 300);
					@include fw(300 $i); } } }
		th, td {
			padding: 10px 20px;
			@include fs(1.4, 100%, 300);
			@include fw(300 $i); }
		.btn {
			@include ib; }
		a {
			@include fs(1.4, 100%, 300);
			@include hover {
				@include co($color - 20); } } } }



.detail.process {
	@include pb(20px);
	.process-status {
		@include bg($gray-light);
		padding: 20px;
		.autor {
			@include bb(thin dotted $gray-light - 30);
			padding: 0 0 10px; }

		h3 {
			@include upper;
			margin: 15px 0; }
		a {
			@include fs(1.4, 130%, 300);
			@include co($color);
			@include block;
			@include mb(5px);
			@include hover {
				@include co($color - 20); }

			i {
				@include mr(10px); } } }

	.intro {
		@include bg($colorSec + 10);
		@include co($gray-dark);
		padding: 20px;
		@include mb(20px);
		@include fs(1.6, 150%, 500); }

	.text {
		@include fs(1.6, 150%, 300); } }


.modal-content {
	@include relative;
	video {
		width: 100% $i;
		height: auto $i; }
	.btn-floating {
		@include top-right(5px, 5px);
		i {
			@include fs(2);
			@include mt(6px); } } }

.areas {
	.icon {
		@include before {
			@include bg($gray + 30); }

		@include after {
			@include bg($gray + 30); }
		i {
			color: $gray + 30 $i; } } }


section.video {
	h1 {
		b {
			color: $colorQua $i; } } }

body {
	.modal-content {
		.videomodal {
			width: 100%;
			height: 350px; } } }


// SECCIÓN AREAS---------------------------------------------
section.areas {
	background: $colorQua;
	padding: 60px 0;
	h2 {
		font-size: 2rem;
		color: $white; }
	.areas2 {
		figure {
			background-size: cover;
			height: 200px;
			background-position: center center;
			background-repeat: no-repeat; }
		h2 {
			font-size: 1.5rem;
			color: $white; }
		p {
			text-align: center;
			font-size: 1.1rem;
			margin-bottom: 45px;
			color: $white; }
		a {
			margin: 20px 0 0 83px;
			padding: 8px 41px;
			border: 1px solid $white;
			color: $white;
			font-size: 1.2rem;
			&:hover {
				color: $color;
				background: $white; } } } }



// SECCIÓN NOTICIAS DESTACADAS------------------------------------------------------------

section.notice {
	text-align: justify;
	width: 100%;
	h2 {
		font-size: 2rem;
		margin: 15px 0 12px;
		vertical-align: top;
		width: 100%;
		text-transform: uppercase;
		text-align: center;
		color: $white;
		font-weight: 300 !important; }
	h4 {
		text-align: center;
		font-weight: 500;
		color: $white;
		margin-bottom: 24px;
		position: relative;
		&:before {
			content: ('');
			height: 2px;
			background-color: $white;
			width: 106px;
			top: 25px;
			position: absolute; } }
	.notice2 {
		border-right: 1px solid $white;
		background: $color;
		display: inline-block;
		vertical-align: top;
		float: left;
		width: 33%;
		height: 300px;
		padding: 63px 0;
		p {
			text-align: center;
			color: $white;
			font-size: 1.2rem;
			font-weight: 300 !important;
			margin-bottom: 30px; }
		a {
			margin-left: 45%;
			color: $white; } }

	.notice3 {
		position: relative;
		float: left;
		width: 34%;
		figure {
			background-position: center center;
			background-size: cover;
			height: 300px;
			background-repeat: no-repeat;
			padding: 0 !important; }
		.not-3 {
			border: 4px solid $white;
			position: absolute;
			top: 0;
			width: 81%;
			height: 225px;
			margin: 40px;
			h2 {
				text-align: center;
				padding: 30px;
				color: $white;
				margin-bottom: 73px; }
			a {
				border: 1px solid $white;
				color: $white;
				padding: 3px 30px;
				margin-left: 31%;
				&:hover {
					background: $white;
					color: $color; } } } } }
