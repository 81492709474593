


//@Contact


main.contact {
	.panel {
		padding-bottom: 0px; }
	h1 {
		margin-bottom: 0px; }
	@include tLeft;
	textarea {
		height: 80px $i; }

	.modal-close {
		@include right; }
	.message-success {
		margin: 0 20px;
		@include fs(3, 100%, 100); }
	#map_wrapper,
	.mapping {
		height: 355px; }

	form {
		padding: 0px;
		@include bg($gray-light);
		@include mt(-10px); }

	label.error {
		@include co($error);
		@include tRight;
		left: $in;
		right: 0;
		@include fs(1, 100%, 300); }

	#map_wrapper {
		border: 4px solid $gray-light;
		@include noScroll;
		@include mt(20px); }
	.contact-data {
		@include bc($gray-light);
		@include co($gray-dark);
		@include mb(20px);
		padding: 10px $space 10px;
		h3 {
			@include mb(20px);
			@include tCenter;
			@include pb(10px);
			@include fs(2, 100%, 400);
			@include upper;
			@include bb(thin solid $colorSec);
			strong {
				color: $color; } }
		ul {
			@include tCenter; }
		li {
			margin: 0 5px;
			@include mb(10px);
			@include ib;
			@include fs(1.4, 100%, 400);
			@include wauto;
			i {
				@include co($color); } } }

	input[type="submit"] {
		@include bg(none);
		@include pointer;
		@include noBorder;
		@include white;
		@include ls(.1rem); }
	.btAction {
		@include bc($colorSix); }
	.datos_contac {
		@include mt(20px); } }
input[type=date], input[type=email], input[type=number], input[type=password], input[type=search], input[type=tel], input[type=text], input[type=url], textarea.materialize-textarea {
	border-bottom-color: $color $i; }
.input-field {
	height: 45px;
	label.active {
		color: $color $i; }
	select {
		border-bottom-color: $color!important; } }
.input-field.textarea_contact {
	height: auto; }

