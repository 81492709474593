
main .text strong {
	@include co($color);
	@include fw(bold); }

.quotes strong {
	@include co($colorTer);
	@include fw(bold); }

//@detail


.detail {
	@include mb(0);
	.gallery-images {
		.col {
			padding: 0px; } }
	h1 {
		@include mb(0);
		padding: 20px 0px; }
	.row {
		@include mb(0); }
	article {
		@include block;
		@include m0;
		@include borderFix; }
	figure {
		@include right;
		@include background;
		margin: 0 0 $space 0;
		@include size(100%);
		@include noScroll;
		@include tCenter;
		img,
		.fancybox-thumb {
			@include size(500px 300px);
			@include block;
			@include relative;
			border: 5px solid $gray + 30;
			@include bradius($bradius); }

		.icon {
			@include bot-right(15px, 15px);
			@include transition(.2s);
			i {
				@include fs(2.4);
				@include white; } } }

	.price {
		@include none; }

	.text {
		margin: 0px 0px;
		@include font-text;
		p {
			margin-bottom: 10px $i; } }
	.video {
		@include centered(20px, 20px);
		@include tCenter; }

	.actions {
		@include tRight;
		@include lh(37px);
		margin: $space 0;
		@include noFont;
		a {
			@include white;
			@include pointer;
			margin: 0 5px;
			@include ib;
			i {
				@include f(3); } } } }



.myProjects {
	@include pb(0 $i);
	@include relative;
	.slider, .slider .slides {
		height: 75.4vh;
		img {
			@include s100; } }
	.slider .indicators {
		bottom: 20px; }

	.project-myText {
		@include top-left;
		width: 320px;
		@include bgBlack(.8);
		@include white;
		@include z(999);
		max-height: 100%;
		//	overflow-x: hidden
		//	overflow-y: auto
		padding: 20px;
		.actions {
			@include w100;
			@include block;
			@include mb(20px);
			@include relative; }
		h1 {
			padding: 10px 0;
			margin: 0px 0px 20px $i;
			@include fs(1.6, 140%, 600);
			@include bg(none);
			@include white;
			border-bottom: 1px solid rgba(255, 255, 255, 0.5);
			box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1), 0 -10px 0 rgba(255, 255, 255, 0.3); }
		.text {
			@include white;

			@include w100;
			max-height: 100%;
			overflow-x: hidden;
			overflow-y: auto;
			height: 50vh; }

		.project-mySlider {
			@include w100;
			@include relative;
			@include z(9); } } }

.detail {
		.quantity {
			background-color: $gray + 50 $i;
			border-bottom: 3px solid #0F1D78;
			position: relative;
			@include bradius($bradius);
			min-height: 196px;
			.white-text {
				margin-top: 30px; }
			h3 {
				font-size: 1.4rem $i;
				position: absolute;
				left: 0;
				padding: 5px;
				top: 6px;
				background-color: $color;
				width: 100%;
				color: $white $i;
				font-weight: 300;
				@include before {
					@include content("");
					@include bc($white);
					width: 2px;
					display: inline-block;
					height: 16px;
					padding-left: 3px;
					margin-right: 6px; }
				i {
					font-size: 1.8rem;
					margin-right: 10px; } }
			ul {
				padding-left: 20px;
				li {
					font-size: 1.4rem;
					@include mb(10px);
					color: $gray-dark;
					list-style:circle {} } } }
		.gallery {
			h3 {
				font-size: 2.8rem;
				font-weight: 300;
				color: $gray-dark;
				padding: 15px 0px;
				strong {
					color: $color; } } }
		.gallery-images {
			.image {
				margin: 20px 0px;
				position: relative;
				height: auto;
				overflow: hidden;
				@include bradius($bradius);
				&:hover {
					.fondo {
						left: 0; } } } }
		.gallery-images {
			.fondo {
				text-align: center;
				width: 100%;
				height: 100%;
				font-weight: 400;
				background: rgba(32, 94, 118, 0.9);
				color: white;
				text-align: center;
				transition: all 0.5s;
				position: absolute;
				bottom: 0;
				left: -100%;
				h3 {
					font-size: 2rem;
					padding-top: 22px;
					margin-bottom: 20px; }
				.fa {
					font-size: 2rem; } } } }
.fancybox-overlay-fixed {
	z-index: 99999999; }



.detail {
	@include fluid {
		.actions {
			@include tCenter; } }
	quantity {
		h3 {
			font-size: 2.5rem $i; } }

	@include mobil {
		figure {
			max-width: 100%;
			margin: 0 0 20px;
			height: 160px;
			@include noFloat;
			img,
			.fancybox-thumb {
				max-width: 100%;
				height: 160px; } } } }
.more-gallery {
	margin-top: 45px;
	margin-bottom: 20px;
	font-size: 2rem;
	&:hover {
		color: $gray-dark $i;
		strong {
			color: $gray-dark; } } }
