//@projects

$height-box: 420px;

main.projects {
	h1 {
		@include mb(20px); }
	.row {
		@include m0; }
	.panel {
		@include mb(20px);
		height: 100%;
		min-height: 600px;
		padding: 10px;
		@include patt(5);
		@include bc($color);
		@include co($white);
		@include fs(1.5, 150%, 300);
		.container {
			width: 100%; }
		.text {
			@include bb(thin dotted $gray);
			@include pb(10px); } }
	.myText {

		@include radius-custom(0px, 0px, 4px, 4px);
		@include z(999999);
		@include bgBlue();
		@include fs(2, 130%, 100);
		padding: 10px;
		@include co(white);
		@include transition(.2s);
		height: 70px;
		@include tCenter;
		@include relative;
		@include mt(-70px);
		pointer-events: none; }

	.myText-active {
		//		+z(-1)
		@include opa(0); }

	.theBox {
		@include relative;
		@include noScroll;
		@include mb(20px); }

	.myCategories {
		@include bg(white); }
	.myPanel {
		.container {
			@include pb(40px); } } }
.da-thumbs {
	@include relative;

	.myBox {

		padding: 0px;
		@include relative;
		border: thin solid $gray-light - 10;
		@include w100;
		@include block;
		height: $height-box;
		@include transition(.2s);
		@include bc(white);
		margin: 0 0 20px;

		@include hover {
			@include bc($gray-light - 15);
			border: thin solid $gray-light - 15; }
		figure {
			height: 160px;
			@include background(cover); }
		h2 {

			@include fs(1.6, 110%, 400);
			padding: 10px 0;
			margin: 20px 20px 0px;
			@include upper;
			@include co($gray-dark); }
		a {
			@include block;
			@include relative;
			margin: 0;
			@include tCenter;
			@include bgWhite;
			@include radius(4px);
			@include s100;
			img {
				@include ib;
				@include relative;
				margin: 30px 0 0;
				height: 80px; }
			div {
				@include absolute;
				@include s100;
				margin: 0;
				span {
					@include block;
					padding: 10px 0;
					margin: 20px 20px 20px;
					@include fs(1.6, 140%, 300);
					@include co($gray-dark);
					border-bottom: 1px solid $color;
					box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1), 0 -10px 0 rgba(055, 055, 055, 0.3); } } } } }


main.projects.categories {
	ul.list-group {
		@include mb(20px);
		@include bg($gray-light);
		@include tCenter;
		> li {
			@include bb(1px solid $gray-light + 10);
			padding: 15px 30px;
			@include transition;
			@include ib;
			> a {
				@include block;
				@include co($colorSec);
				@include fs(1.6, 100%, 400);
				@include before {
					@include co($colorSec $i); } }

			@include hover {
				@include bg($white);
				> a {
					@include co($color $i);
					@include before {
						@include co($color $i); } } }
			> ul {
				@include none; } }
		> .class_active {
			margin: 0 $i;
			> a {
				@include co($color);
				@include before {
					content: '\f111'; }
				@include hover {
					@include co($colorSec); } } } }
	.text {
		@include mb(20px); }

	.ico-more {
		@include none; }
	.btn {
		margin-top: 20px; } }



main.projects.categories {
	ul.list-group {

		> li {
			a {
				@include before {
					font-family: fontAwesome;
					color: $white;
					@include mr(10px);
					content: '\f10c';
					@include transition(.4s); }

				@include hover {
					@include before {
						content: '\f192'; } } }


			@include nth1 {
				a {
					@include before {
						content: '\f0ae'; } } } } } }


.distribuitors {
	@include bg($gray-light);
	@include tCenter;
	padding: 0px 0;
	h2 {
		@include co($colorSec);
		@include fs(4, 100%, 700);
		@include ib;
		@include wauto;
		@include upper;
		@include radius(4px);
		@include mb(20px);
		strong {
			@include co($color); } }
	.button-group {
		@include noFont;
		button {
			@include font-site;
			margin: 0 5px;
			padding: 8px 10px;
			@include bc($colorSec);
			@include pointer;
			@include radius(4px);
			@include fs(1.6, 100%, 400);
			@include co($color);
			@include transition(.2s);
			@include mb(10px);
			border: 1px solid $colorSev; }
		.button:active,
		.button:hover,
		.button.is-checked {
			@include bc($colorSev);
			@include co($white); } }

	.element-item {
		width: 32%;
		padding: 0px;
		height: 310px;
		display: inline-block;
		position: relative;
		@include mb(20px);
		@include bb(thin solid $gray-light - 30);
		@include relative;
		@include noScroll;
		@include radius(0);
		@include pointer;
		margin-right: 5px;

		@include hover {
			.text {
				@include top-left(0,0); } }
		> div {
			height: 100%;
			@include radius(6px);
			@include relative; }
		h3 {
			margin: 10px;
			@include fs(1.8, 100%, 400);
			@include upper; }
		.text {
			@include fs(1.6, 130%, 300);
			@include font-text; }

		.btn-floating {
			@include bot-right(10px, 50%);
			@include mr(-14px); }

		figure {
			height: 240px;
			@include background(cover);
			@include radius(6px); }
		.text {
			@include top-left(-300px, 0);
			@include bgBlack(.7);
			height: 240px;
			@include co(white);
			@include transition(.2s);
			padding: 20px 20px 60px;
			@include radius(6px);
			width: 100%; } } }

.team.button-group {
	button {
		margin: 0 5px 10px;
		padding: 8px 10px;
		background-color: $white;
		cursor: pointer;
		border-radius: 4px;
		font-size: 1.6rem;
		line-height: 100%;
		font-weight: 400;
		color: $color;
		border: 1px solid $color; } }
.workTeam {
	button {
		width: 100%; } }

.team.button-group button:hover {
			background-color: $color;
			color: $white; }

@media (max-width: 768px) {
	.distribuitors .element-item {
		width: 50%; } }

@media (max-width: 700px) {
	.distribuitors {
		.element-item {
			.text {
				line-height: 100%; } } } }




@media (max-width: 1024px) {
	.distribuitors .element-item {
		height: auto; } }

@media (max-width: 500px) {
	.distribuitors .element-item {
		width: 100%; } }
